import Vue from 'vue'
import VueRouter from 'vue-router'
import index from "@/views/index/index.vue";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: index,
  },
  // 共育基地
  {
    path: '/coEducationalBase',
    name: 'CoEducationalBase',
    component: () => import('@/views/coEducationalBase/index.vue'),
  },
  // 联系我们
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: () => import('@/views/contactUs/index.vue'),
  },
  // 培训认定
  {
    path: '/trainingCertification',
    name: 'TrainingCertification',
    component: () => import('@/views/trainingCertification/index.vue'),
  },
  // 动态监管
  {
    path: '/dynamicSupervision',
    name: 'DynamicSupervision',
    component: () => import('@/views/dynamicSupervision/index.vue'),
  },
  // 诚信创建
  {
    path: '/integrityCreation',
    name: 'IntegrityCreation',
    component: () => import('@/views/integrityCreation/index.vue'),
  },
  // 协会介绍
  {
    path: '/associationIntroduction',
    name: 'AssociationIntroduction',
    component: () => import('@/views/associationIntroduction/index.vue'),
  },
  // 信用服务
  {
    path: '/creditServices',
    name: 'CreditServices',
    component: () => import('@/views/creditServices/index.vue'),
  },
  // 保函验真
  {
    path: '/guaranteeVerification',
    name: 'GuaranteeVerification',
    component: () => import('@/views/guaranteeVerification/index.vue'),
  },
  // 注册
  {
    path: '/registrationPage',
    name: 'RegistrationPage',
    component: () => import('@/views/registrationPage/index.vue'),
  },
  // 通知公告
  {
    path: '/notificationAnnouncement',
    name: 'NotificationAnnouncement',
    component: () => import('@/views/notificationAnnouncement/index.vue'),
  },
  // 通知公告详情
  {
    path: '/notificationAnnouncementDetails',
    name: 'NotificationAnnouncementDetails',
    component: () => import('@/views/notificationAnnouncementDetails/index.vue'),
  },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    component: () => import('@/views/personalCenter/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
