<template>
    <div class="bottomPageClass">
        <div class="jsuastcClass">
            <div class="jsuaisClass">
                <img class="jvascuClass" src="@/static/bottomPage/imglog.png" alt="">
            </div>
            <div class="kzxcoClass">
                <div class="gisuaiClass">
                    <div class="vusjziaClass">
                        <span class="span1">
                            联系我们
                        </span>
                        <span class="span2">
                            024-83963827
                        </span>
                        <span class="span3">
                            邮箱：sysxyxh@163.com
                        </span>
                        <span class="span4">
                            地址：辽宁省沈阳市沈河区市府大路
                        </span>
                    </div>
                    <div class="usiaskxiClass">
                        <span class="span1">
                            友情链接
                        </span>
                        <div class="nbzxcClass">
                            <span class="span2" @click="gunjiaClick('https://www.cbirc.gov.cn/')">
                                国家金融监督管理总局
                            </span>
                            <span class="span3" @click="gunjiaClick('http://www.csrc.gov.cn/')">
                                中国证券监督管理委员会
                            </span>
                        </div>
                        <div class="kjzxcClass">
                            <div class="mzxcClass" @click="gunjiaClick('https://www.samr.gov.cn/')">
                                国家市场监督管理总局
                            </div>
                            <div class="zxckClass" @click="gunjiaClick('https://www.ndrc.gov.cn/')">
                                中华人民共和国国家发展和改革委员会
                            </div>
                        </div>
                    </div>
                </div>
                <div class="isoqisClass">
                    <div class="isoizoClass">
                        <span class="span">
                            公众号
                        </span>
                        <img class="img" src="@/static/bottomPage/erweima.png" alt="">
                    </div>
                </div>
            </div>

        </div>
        <div class="vusiasClass"></div>
        <div class="wvkjzxcoiClass">
            <div class="whzxckClass">
                <div class="wjzxckjClass">
                    <div class="wkzxckjClass">
                        <div class="wlzxckjaClass" @click="gunjiaClick('https://beian.miit.gov.cn/')">
                            辽ICP备2020013760号-1  沈阳信用协会
                        </div>
                        <div class="wziuzxcClass">
                            违法和不良信息举报电话：024-83963827
                        </div>
                    </div>
                    <div class="wxkjasdClass">
                        举报邮箱：sysxyxh@163.com
                    </div>
                </div>
                <img class="wchjzxcoClass" src="@/static/enterpriseDetails/jinghu.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        gunjiaClick(path) {
            window.open(path)
        }
    }
}
</script>
<style lang="scss" scoped>
.bottomPageClass {
    background-color: #B92B30;
    min-height: 224px;
    $width: 1200px;
    padding-top: 23px;
    padding-bottom: 22px;
    font-family: '思源黑体-Regular' !important;

    .jsuastcClass {
        width: $width;
        margin: 0 auto;

        .jsuaisClass {
            .jvascuClass {
                width: 158px;
                height: 38px;
                object-fit: cover;
            }
        }

        .kzxcoClass {
            margin-top: 31px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .gisuaiClass {
                flex: 1;
                display: flex;


                @mixin jfusi {
                    display: flex;
                    flex-direction: column;
                }



                .vusjziaClass {
                    @include jfusi();


                    .span1 {
                        font-weight: bold;
                        font-size: 18px;
                        color: #FFFFFF;
                    }

                    .span2 {
                        margin-top: 8px;
                        font-weight: bold;
                        font-size: 24px;
                        color: #FFFFFF;
                    }

                    .span3 {
                        margin-top: 4px;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFC4C6;
                    }

                    .span4 {
                        margin-top: 7px;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFC4C6;
                    }
                }

                .usiaskxiClass {
                    @include jfusi();
                    margin-left: 120px;

                    .span1 {
                        font-weight: bold;
                        font-size: 18px;
                        color: #FFFFFF;
                    }

                    .nbzxcClass {
                        margin-top: 12px;
                        display: flex;
                        align-items: center;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFC4C6;

                        .span2 {
                            cursor: pointer;
                        }

                        .span3 {
                            cursor: pointer;
                            display: block;
                            margin-left: 35px;
                        }
                    }

                    .kjzxcClass {
                        margin-top: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .mzxcClass {
                            font-weight: 400;
                            font-size: 14px;
                            color: #FFC4C6;
                            cursor: pointer;
                        }

                        .zxckClass {
                            margin-left: 35px;
                            font-weight: 400;
                            font-size: 14px;
                            color: #FFC4C6;
                            cursor: pointer;
                        }
                    }

                }
            }

            .isoqisClass {
                display: flex;

                @mixin ksiasu {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                @mixin hjzxcl {
                    font-weight: bold;
                    font-size: 18px;
                    color: #FFFFFF;
                }

                @mixin nbzxck {
                    margin-top: 12px;
                    width: 88px;
                    height: 88px;
                    border-radius: 5px;
                    overflow: hidden;
                }

                .isoizoClass {
                    @include ksiasu();

                    .span {
                        @include hjzxcl();
                    }

                    .img {
                        @include nbzxck();
                    }
                }

                .giusizoClass {
                    @include ksiasu();
                    margin-left: 25px;

                    .span {
                        @include hjzxcl();
                    }

                    .img {
                        @include nbzxck();
                    }
                }
            }
        }
    }

    .vusiasClass {
        width: $width;
        margin: 0 auto;
        margin-top: 35px;
        height: 1px;
        background-color: #D4474C;
    }

    .wvkjzxcoiClass {
        background-color: #B92B30;

        .whzxckClass {
            width: $width;
            background-color: #B92B30;
            margin: 0 auto;
            padding-top: 19px;
            padding-bottom: 19px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .wjzxckjClass {
                @mixin kjzxc {
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFC4C6;
                }

                .wkzxckjClass {
                    display: flex;
                    align-items: center;

                    .wlzxckjaClass {
                        @include kjzxc();
                        cursor: pointer;
                    }

                    .wziuzxcClass {
                        @include kjzxc();
                        margin-left: 92px
                    }
                }

                .wxkjasdClass {
                    @include kjzxc();
                    margin-top: 10px;
                }
            }

            .wchjzxcoClass {
                width: 38px;
                height: 50px;
            }
        }
    }
}
</style>
